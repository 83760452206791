<template>
  <div>
    <v-snackbar class="snakbar-sty rounded-pill" transition="slide-x-reverse-transition" v-model="snackbar" :timeout="3000" :value="true" :color="valdatadis ? '#22ab94' : '#2A2A2A'" absolute outlined>
      <v-icon class="mr-2" :color="valdatadis ? '#22ab94' : '#2A2A2A'">mdi-alert-outline</v-icon>
      {{ toasttext }}
      <v-icon @click="snackbar = false" class="float-right" :color="valdatadis ? '#22ab94' : '#2A2A2A'">mdi-close-circle</v-icon>
    </v-snackbar>
    <div class="body-row pb-3 px-4 px-md-6 px-lg-8 pt-lg-6">
      <img src="@/assets/zebu.svg" class="ml-n1" style="max-width: 90px" alt="zebulogo" />
      <v-card class="elevation-0">
        <p class="subtitle-2 font-weight-bold mb-5 txt-666">Welcome to Zebu</p>
        <p class="headline font-weight-black mb-8">Change or Reset Password</p>
        <p class="font-weight-medium fs-17 mb-0">
          Enter your Client ID
          <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
            <path
              d="M5.10423 0.5L4.85634 3.86901L7.95493 2.53944L8.56338 4.17324L5.13803 4.98451C5.30329 5.17981 5.48732 5.38638 5.69014 5.60423C5.89296 5.81455 6.09577 6.02864 6.29859 6.24648C6.50892 6.46432 6.71549 6.6784 6.91831 6.88873C7.12113 7.09906 7.30892 7.29437 7.48169 7.47465L6.12958 8.5L4.25916 5.6831L2.33239 8.5L0.991549 7.47465L3.35775 4.98451L0 4.12817L0.51831 2.53944L3.68451 3.88028L3.38028 0.5H5.10423Z"
              fill="#EF5350"
            />
          </svg>
        </p>

        <v-form ref="form" v-model="valid" @submit.prevent="valiDate()">
          <v-text-field placeholder="Client ID" class="mb-1" disabled oninput="this.value = this.value.toUpperCase()" v-model="client_id">
            <template #prepend-inner>
              <div class="mr-2">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M14.1333 12.6438C14.1335 12.4937 14.0975 12.3457 14.0282 12.2125C13.959 12.0792 13.8587 11.9647 13.7357 11.8785C12.052 10.7881 10.0709 10.248 8.06667 10.3329C6.06245 10.248 4.08138 10.7881 2.3976 11.8785C2.27466 11.9647 2.17432 12.0792 2.1051 12.2125C2.03588 12.3457 1.99983 12.4937 2 12.6438V14.9996H14.1333V12.6438Z"
                    stroke="#212121"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path d="M8.06647 7.53333C9.8706 7.53333 11.3331 6.0708 11.3331 4.26667C11.3331 2.46254 9.8706 1 8.06647 1C6.26234 1 4.7998 2.46254 4.7998 4.26667C4.7998 6.0708 6.26234 7.53333 8.06647 7.53333Z" stroke="#212121" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
            </template>

            <template #append>
              <a v-if="Otp_screen" style="text-decoration: none; color: #0037b7; font-size: 12px" href=""><b>Change</b></a>
            </template>
          </v-text-field>

          <p class="font-weight-medium fs-17 mb-0">
            Enter your Old Password
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
              <path
                d="M5.10423 0.5L4.85634 3.86901L7.95493 2.53944L8.56338 4.17324L5.13803 4.98451C5.30329 5.17981 5.48732 5.38638 5.69014 5.60423C5.89296 5.81455 6.09577 6.02864 6.29859 6.24648C6.50892 6.46432 6.71549 6.6784 6.91831 6.88873C7.12113 7.09906 7.30892 7.29437 7.48169 7.47465L6.12958 8.5L4.25916 5.6831L2.33239 8.5L0.991549 7.47465L3.35775 4.98451L0 4.12817L0.51831 2.53944L3.68451 3.88028L3.38028 0.5H5.10423Z"
                fill="#EF5350"
              />
            </svg>
          </p>

          <v-text-field :disabled="valdatadis" v-model="oldpassword" :append-icon="oldpwseye ? 'mdi-eye' : 'mdi-eye-off'" :rules="oldpwsRules" :type="oldpwseye ? 'text' : 'password'" class="mb-1" placeholder="Old Password or Received Password" @click:append="oldpwseye = !oldpwseye">
            <template #prepend-inner>
              <div class="mr-2">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M5.25 10.875H12.75M4.5 7.5H4.5075M7.5 7.5H7.5075M10.5 7.5H10.5075M13.5 7.5H13.5075M3.9 13.5H14.1C14.9401 13.5 15.3601 13.5 15.681 13.3365C15.9632 13.1927 16.1927 12.9632 16.3365 12.681C16.5 12.3601 16.5 11.9401 16.5 11.1V6.9C16.5 6.05992 16.5 5.63988 16.3365 5.31901C16.1927 5.03677 15.9632 4.8073 15.681 4.66349C15.3601 4.5 14.9401 4.5 14.1 4.5H3.9C3.05992 4.5 2.63988 4.5 2.31901 4.66349C2.03677 4.8073 1.8073 5.03677 1.66349 5.31901C1.5 5.63988 1.5 6.05992 1.5 6.9V11.1C1.5 11.9401 1.5 12.3601 1.66349 12.681C1.8073 12.9632 2.03677 13.1927 2.31901 13.3365C2.63988 13.5 3.05992 13.5 3.9 13.5Z"
                    stroke="#212121"
                    stroke-width="1"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </template>
          </v-text-field>
          <p class="font-weight-medium fs-17 mb-0">
            Enter your New Password
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
              <path
                d="M5.10423 0.5L4.85634 3.86901L7.95493 2.53944L8.56338 4.17324L5.13803 4.98451C5.30329 5.17981 5.48732 5.38638 5.69014 5.60423C5.89296 5.81455 6.09577 6.02864 6.29859 6.24648C6.50892 6.46432 6.71549 6.6784 6.91831 6.88873C7.12113 7.09906 7.30892 7.29437 7.48169 7.47465L6.12958 8.5L4.25916 5.6831L2.33239 8.5L0.991549 7.47465L3.35775 4.98451L0 4.12817L0.51831 2.53944L3.68451 3.88028L3.38028 0.5H5.10423Z"
                fill="#EF5350"
              />
            </svg>
          </p>

          <v-text-field :disabled="valdatadis" v-model="newpassword" :append-icon="newpwseye ? 'mdi-eye' : 'mdi-eye-off'" :rules="newpwsRules" :type="newpwseye ? 'text' : 'password'" class="mb-1" placeholder="New Password" @click:append="newpwseye = !newpwseye">
            <template #prepend-inner>
              <div class="mr-2">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M5.25 10.875H12.75M4.5 7.5H4.5075M7.5 7.5H7.5075M10.5 7.5H10.5075M13.5 7.5H13.5075M3.9 13.5H14.1C14.9401 13.5 15.3601 13.5 15.681 13.3365C15.9632 13.1927 16.1927 12.9632 16.3365 12.681C16.5 12.3601 16.5 11.9401 16.5 11.1V6.9C16.5 6.05992 16.5 5.63988 16.3365 5.31901C16.1927 5.03677 15.9632 4.8073 15.681 4.66349C15.3601 4.5 14.9401 4.5 14.1 4.5H3.9C3.05992 4.5 2.63988 4.5 2.31901 4.66349C2.03677 4.8073 1.8073 5.03677 1.66349 5.31901C1.5 5.63988 1.5 6.05992 1.5 6.9V11.1C1.5 11.9401 1.5 12.3601 1.66349 12.681C1.8073 12.9632 2.03677 13.1927 2.31901 13.3365C2.63988 13.5 3.05992 13.5 3.9 13.5Z"
                    stroke="#212121"
                    stroke-width="1"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </template>
          </v-text-field>

          <div class="mt-3" :style="`cursor: ${valid != true || cardloader == true ? 'not-allowed' : 'auto'};`">
            <v-btn height="48px" :loading="cardloader" :disabled="!valid || cardloader" @click="valiDate()" type="submit" color="#000" class="text-none rounded-pill elevation-0 mb-1" block><span class="white--text subtitle-1 font-weight-medium">Continue</span></v-btn>
            <v-btn text class="font-weight-bold text-none primary--text pa-0" :ripple="false" small to="/mynt">back to login</v-btn>
          </div>
        </v-form>
        <p class="subtitle-2 font-weight-medium black--text mb-0 text-center mt-2">Don't have an account yet? <a style="text-decoration: none; color: #0037b7" href="https://oa.mynt.in?ref=desk">Sign Up</a></p>
      </v-card>
      <v-footer padless absolute color="#fff">
        <v-col class="text-center font-weight-medium fs-10 px-lg-8 px-md-6 px-lg-8 pb-2 txt-666" cols="12"
          ><span class="font-weight-bold fs-12 black--text"> Zebu Share and Wealth Managements Pvt. Ltd. </span><br />
          SEBI Registration No: INZ000174634 | NSE : 13179 | BSE : 6550 | MCX : 55730 | CDSL : 12080400 | AMFI ARN : 113118 | Research Analyst : INH200006044
        </v-col>
      </v-footer>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import api from "../api.js";
import {sha256} from "js-sha256";

export default {
  data() {
    return {
      client_id: "",
      password: "",
      valdatadis: false,
      valid: false,

      errorOTPDisp: false,
      Otp_screen: false,
      cardloader: false,
      resendloader: false,
      toasttext: null,
      resendText: false,
      snackbar: false,
      wayis: true,

      newpwseye: false,
      newpassword: "",
      newpwsRules: [
        (v) => !!v || "New Password is required",
        (v) => v.length >= 8 || "New Password of minimum 8 characters",
        (v) => (v || "").indexOf(" ") < 0 || "No spaces are allowed",
        (v) => /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(v) || "New Password must contain at least lowercase letter, one number, a special character and one uppercase letter",
      ],
      oldpwseye: false,
      oldpassword: "",
      oldpwsRules: [(v) => !!v || "Old Password is required", (v) => (v && v.length >= 4) || "Enter your full password"],
      pwseye: false,
      progress: 0,
    };
  },

  mounted() {
    this.client_id = new URL(window.location.href).searchParams.get("uid");

    // var actid = localStorage.getItem("Muserid");
  },
  methods: {
    valiDate() {
      var url = new URL(window.location.href).searchParams.get("url");

      var oldshapass = sha256(this.oldpassword);
      if (this.$refs.form.validate() != false && this.progress == 0) {
        this.cardloader = true;
        this.progress = 1;
        var data = `jData={"uid":"${this.client_id}","oldpwd":"${oldshapass}","pwd":"${this.newpassword}"}`;
        var config = {
          method: "post",
          url: `${api.mynturl}/Changepwd`,
          headers: {
            "Content-Type": "text/plain",
            Cookie: "NWC_ID=3e03100f85c0155ffb62d5d9bcc0ee273e804c489e430ddb9e00eda1aab50f54",
          },
          data: data,
        };
        var axiosThis = this;
        axios(config)
          .then(function (response) {
            if (response.data.dmsg && (response.data.dmsg.includes("Password Change Success") || response.data.dmsg.includes("Change Success"))) {
              axiosThis.valdatadis = true;
              setTimeout(function () {
                axiosThis.$router.push(`/mynt?url=${url ? url : ""}&cli=${axiosThis.client_id}&pws=${axiosThis.newpassword}`);
              }, 2400);
            } else {
              axiosThis.cardloader = false;
            }
            if (response.data.emsg == "Error Occurred : Invalid Old Password") {
              axiosThis.oldpassword = "";
            }
            axiosThis.snackbar = true;
            axiosThis.toasttext = response.data.dmsg ? response.data.dmsg : response.data.emsg ? response.data.emsg : response.data;
            axiosThis.progress = 0;
          })
          .catch(function (error) {
            console.log(error);
            axiosThis.snackbar = true;
            axiosThis.toasttext = error;
            axiosThis.progress = 0;
            axiosThis.cardloader = false;
          });
      }
    },
  },
};
</script>
