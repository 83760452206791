import Vue from 'vue'
import VueRouter from 'vue-router'
import HelloWorld from '../components/HelloWorld.vue'
import HomeView from '../views/HomeView.vue'
import DeskMynt from '../views/DeskMynt.vue'
import MyntChangePws from '../views/MyntChangePws.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'HelloWorld',
    name: 'layout',
    component: HelloWorld,
    children: [
      {
        path: '/',
        name: 'mobile',
        component: HomeView
      },
      {
        path: '/mynt',
        name: 'mynt desk',
        component: DeskMynt
      },
      {
        path: '/change_password',
        name: 'Change password',
        component: MyntChangePws
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
