export default{ 
api_url : 'https://copy.mynt.in/',
// api_url : 'http://192.168.5.192:5001', 
mynturl: "https://go.mynt.in/NorenWClientTP/",

// qrurl: 'http://192.168.5.192:5001'
// qrurl: 'https://copy.mynt.in/'

// api_url : 'https://zsync.zebull.in/zebu' 

}
// https://zsync.zebull.in
// http://192.168.5.162:7000/invezt