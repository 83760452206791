<template>
  <div>
    <v-container class="body-card overflow-hidden pa-0" fill-height>
      <div class="body-part px-0 px-md-0">
        <v-card width="100%" class="ma-auto rounded-xl overflow-hidden body-temp">
          <router-view />
        </v-card>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    //
  }),
}
</script>

<style>
@import '../assets/style.css';
</style>
